import React, {useState} from "react";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import LearnMoreHub from "../components/sections/hubs/learn-more-hub"
import ContactForm from "../components/contact-form";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import bannerImage from "../images/box-headers/contact_us.jpg";

const ContactUs = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout title="Contact Us">
      <Banner className="font-white banner-contact">
      <SEO title="Contact Us - Internet and Software Provider - VostroNet">
        <meta name="description" content="Engage with VostroNet, the leading Internet and Software Provider"/>
      </SEO>
        <Container className="banner-container">
          <Row>
            <Col xs={12}>
              <Container fluid className="no-gutters">
                <Row>
                  <Col>
                    <div className={`banner-title`}>
                      {"Contact Us"}
                    </div>
                  </Col>
                </Row>
                <Row className="contact-content" >
                  <Col>
                    <Container fluid>
                      <Row>
                        <ContactWidget
                          icon="fad fa-hands-helping"
                          title="Talk to our Sales Team"
                          subtitle={"We'll help you find the right solution and pricing for your environment."}
                          btnText="Contact Sales"
                          onClick={handleShow}
                          />
                        <ContactWidget
                          icon="fad fa-user-headset"
                          title="Support and Help Desk"
                          subtitle={"Find helpful answers or engage with our Customer Service Team."}
                          btnText="Go to the help center"
                          href="https://help.vostro.cloud/"
                          hrefTarget="_blank" />
                      </Row>
                    </Container>
                  </Col>
                  
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Banner>

      {/* <Banner subpage data={{
        title: {
          name: "Contact Us",
        },
        subtitles: [
          {
            name: `VostroNet's support desk is here to help you 24/7, whether you have questions regarding our solutions or are looking for assistance to connect your devices to our network.`,
            className: "banner-text-small",
          },
          {
            name: `Users can engage with the support team via email, Facebook or through the phone numbers listed below.`,
            className: "banner-text-small",
          },
        ],
        buttonData: {
          name: "Learn more",
        },
        img: bannerImage,
      }} /> */}
      <div className="contact-content general">
        <ContactForm show={show} handleClose={handleClose} />
        <Container className="py-5">
          <Row className="align-items-center">
            <Col>
              <Container fluid className="no-gutters">
                <Row className={"corporate-title"}>
                  <Col>
                    {"Our Corporate Details"}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="desc my-4">
                      <div>{"GPO Box 3154"}</div>
                      <div>{"Level 1, 1 Ulster Lane"}</div>
                      <div>{"Brisbane QLD 4000"}</div>
                      <div>{"Australia"}</div>
                    </div>
                    <div className="desc my-4">
                      <div>
                        <a href="tel:+61283192299">
                          {"Tel (AU) +61-2-8319-2299"}
                        </a>
                      </div>
                      <div>
                        <a href="tel:+6498871793">
                          {"Tel (NZ) +64-9-887-1793"}
                        </a>
                      </div>
                      <div>
                        <a href="mailto:corporate@vostronet.com">
                          {"corporate@vostronet.com"}
                        </a>
                      </div>
                      <div>
                        <a href="mailto:help@vostronet.com">
                          {"help@vostronet.com"}
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col className="d-none d-lg-block">
              <img className="img-fluid shadow" src={bannerImage} alt="banner" />
            </Col>
          </Row>
          <Row>
            
          </Row>
        </Container>
      </div>
      <LearnMoreHub />
    </Layout>
  );
}


function ContactWidget(props) {
  return (<Col xs={12} sm={6}>
    <div className="contact-widget paper shadow">
      <Container className="no-gutters fh" fluid>
        <Row className="icon-row">
          <Col>
            <div className="icon-container">
              <i className={props.icon} />
            </div>
          </Col>
        </Row>
        <Row className="title-row">
          <Col>
            <div className="title-container">
              {props.title}
            </div>
          </Col>
        </Row>
        <Row className="subtitle-row fh-element">
          <Col>
            <div className="subtitle-container">
              {props.subtitle}
            </div>
          </Col>
        </Row>
        <Row className="contact-btn-row">
          <Col>
            <div className="btn-container">
              {props.href ? (<a className={`btn btn-${props.btnColour || "green"}`} 
                rel="noopener"
                target={props.hrefTarget}
                href={props.href}>
                {props.btnText}
              </a>) : (<Button variant={props.btnColour || "green"} onClick={props.onClick}>
                {props.btnText}
              </Button>)} 
              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Col>);
}


// function FormControl({label, onChange, controlId, children, ...rest}) {
//   return (<Form.Group controlId={controlId}>
//     <Form.Label>{label}</Form.Label>
//     <Form.Control onChange={(e) => {
//       return onChange(e.currentTarget.value);
//     }} {...rest}>
//       {children}
//     </Form.Control>
//   </Form.Group>)
// }

export default ContactUs;


